@use "sass:map";
@import "buttons-color-scheme";

@mixin toolbar-button {
  .enabled {
    .toolbar-button.dx-button-mode-text, .hidden-menu-button.dx-button-mode-text,
    .toolbar-button.dx-button-mode-outlined, .toolbar-button.dx-button-mode-contained {
      &.dx-button-normal {
        color: $gray-2;

        &.dx-state-hover {
          background: $primary-15;
        }
      }

      i, span, svg, .dx-icon {
        color: inherit !important;
      }
    }

    .toolbar-group-button {
      &.styling-mode-text, &.styling-mode-outlined{
        &.color-scheme-normal {
          .dx-button {
            &.dx-state-hover {
              background: $primary-15;

              .dx-icon-spindown {
                background: $primary-30;
                color: $primary;
              }
            }
          }

          i, span, svg {
            color: $gray-2;
          }
        }

        &.color-scheme-success {
          .dx-button {
            @include flat-dropdown-button($button-success-color);
          }
        }

        &.color-scheme-default {
          .dx-button {
            @include flat-dropdown-button($button-default-color);
          }
        }

        &.color-scheme-danger {
          .dx-button {
            @include flat-dropdown-button($button-danger-bg);
          }
        }
      }

      &.styling-mode-contained {
        &.color-scheme-normal {
          .dx-button {
            &.dx-state-hover {
              background: $primary-15;

              .dx-icon-spindown {
                background: $primary-30;
                color: $primary;
              }
            }
          }

          i, span, svg {
            color: $gray-2;
          }
        }

        &.color-scheme-success {
          .dx-button {
            @include contained-drop-down($button-success-color);
          }
        }

        &.color-scheme-default {
          .dx-button {
            @include contained-drop-down($button-default-color);
          }
        }

        &.color-scheme-danger {
          .dx-button {
            @include contained-drop-down($button-danger-bg);
          }
        }
      }
    }
  }
  .disabled {
    &, .toolbar-group-button, .toolbar-button {
      opacity: 1 !important;
    }

    .dx-button-mode-text {
      &.dx-button-normal {
        i, span {
          color: $gray;
        }

        svg {
          path {
            fill: $gray;
          }

          rect {
            stroke: $gray;
          }
        }
      }
    }
  }

  .toolbar-group-button, .toolbar-button, .dx-dropdownmenu-button {
    i:not(.custom-icon).dx-icon:not(.dx-icon-spindown) {
      @include toolbar-icon();
    }

    i.custom-icon {
      @include custom-toolbar-icon();
    }

    img.dx-icon, svg.dx-icon {
      height: 14px;
      width: 14px;
    }

    .dx-button-text {
      text-transform: none;
    }
  }
}

@mixin custom-toolbar-icon() {
  font-size: 14px !important;
  height: 16px;
  width: 16px;
}


@mixin toolbar-icon() {
  font-size: $table-toolbar-button-icon-size !important;
  height: 16px;
  width: 16px;
}

@mixin toolbar-settings-button() {
  height: $table-toolbar-height;

  &.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 2px;
  }

  &.dx-state-hover {
    background: rgba(0, 0, 0, .08);
  }

  .dx-icon {
    @include toolbar-icon();
    color: rgba(0, 0, 0, .87);
  }
}
