@import "variables";

$topbar-item-color: $white;
$topbar-height: 42px;
$top-bar-open-button-bg: $primary-110;

.topbar-item {
  color: $topbar-item-color;
  .dx-item {
    border-radius: 50% !important;
  }

  .dx-button-text {
    color: $white;
  }
  .dx-button-has-text .dx-button-content {
    overflow: visible;
  }

  .dx-icon {
    color: $topbar-item-color;
  }
}
