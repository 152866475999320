@mixin hidden-modifier {
  &-hidden {
    display: none;
  }
}

.filter-panel {
  height: 100%;
  padding: 5px 0 18px 0;
  position: relative;

  .dx-button {
    border-radius: 5px;
  }

  &__content {
    overflow: hidden;
    position: relative;

    &-visible_settings {
      height: 100%;
    }

    &-hidden_settings {
      height: auto;
      display: flex;
    }
  }

  .dx-button-text {
    text-transform: none;
    font-size: 0.75rem;
  }

  &__buttons-panel-wrapper {
    margin-bottom: 65px;
  }

  #filter-panel-button-cancel {
    min-width: unset;
    padding-right: 6px;
    padding-left: 6px;
  }

  &__buttons-panel {
    padding: 14px 0 10px 0;
    border-top: 1px solid $gray;
    margin-top: 5px;

    &__button {
      min-width: 90px;
    }

    .clear {
      color: $gray-2;
    }

    .apply {
      box-shadow: 0 2px 10px rgba(24, 89, 255, 0.5);
    }
  }

  .filter-panel__buttons-panel-wrapper {
    padding-right: 12px;

    .filter-panel__buttons-panel div:first-child .dx-button-content {
      padding-left: 5px;
      text-align: left;
    }

    @include hidden-modifier;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
    @include hidden-modifier;
  }

  &__settings-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    @include hidden-modifier;
  }

  .filter-settings {
    &__show, &__hide {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .filter-settings__toggle-button {
    &.filter-settings__toggle-back {
      margin-right: 12px;

      .dx-button {
        .dx-button-content {
          width: 100%;
          display: inline-block;

          .dx-icon {
            text-align: left;
          }

          .dx-button-text {
            float: right;
          }
        }
      }
    }

    .dx-button-content {
      padding-left: 0;
      padding-right: 0;

      .dx-icon {
        margin-right: 0;
        text-align: right;
      }
    }
  }

}
