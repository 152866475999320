.layout-body {
  min-height: 0;
  flex: 1 1;

  &.dx-drawer-right {
    .dx-resizable {
      transform: none !important;
    }
  }
}
