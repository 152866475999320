@use 'sass:map';
@import 'variables';
@import 'tables/variables';
@import "configs/base";
@import "./mixins";

$popup-top-toolbar-height: 42px;
$popup-title-color: $primary;
$popup-bluebar-height: 10px;
$popup-bluebar-bg: $primary;

.react-draggable {
  border-radius: 5px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  display: flex;
  z-index: 1000;
  cursor: default !important;
  transition: opacity 0.2s ease-in;
}

.mobile-fullscreen-qr-scanner {
  .react-draggable {
    transform: unset !important;
  }
}


$popup-content-padding: 0 30px 30px;
$popup-title-padding: 0 30px;

.popup {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;

  .popup-wrapper {
    padding: $popup-content-padding;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    & .simplebar-content-wrapper {
      outline: none;
    }
  }

  .popup-content {
    flex: 1;
    overflow: hidden;
  }

  .popup-title-container {
    padding: $popup-title-padding;
    position: relative;

    .popup__title-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: $popup-top-toolbar-height;
      margin-top: $popup-bluebar-height + 5px;

      &.no-title-text {
        justify-content: flex-end !important;
        height: $popup-top-toolbar-height - 20px;
      }

      .popup-title {
        flex: 1;
        padding-right: 10px;
      }

      .popup__title__text-modifier {
        color: $gray-7;
        font-weight: 500;
      }

      .popup__title-suffix-icons {
        align-self: flex-start;
        margin-top: 4.5px;
        font-size: 1.3rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .dx-button-content {
          padding: 0;
        }

        i {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }

  .cursor-move {
    cursor: move;
  }

  .cursor-default {
    cursor: default;
  }

  .popup-title {
    flex: 0;
    height: 100%;
    font-weight: 500;
    line-height: 23px;
    font-style: normal;
    color: $popup-title-color;
    padding: 0 40px 10px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .popup__title__text-modifier {
    padding-right: 5px;
    line-height: 23px;
  }

  .bluebar {
    position: absolute;
    left: 0;
    top: 0;
    height: $popup-bluebar-height;
    width: 100%;
    background-color: $popup-bluebar-bg;
  }

  .dx-popup-title {
    padding: 0;
    border: 0;
  }

  .dx-overlay-content {
    &.dx-popup-draggable {
      .dx-popup-content {
        padding: $popup-content-padding;
      }
    }
  }
}

.dx-scrollview-content {
  .dx-list-select-all {
    display: flex;
    align-items: center;
  }

  .dx-list-select-all-label {
    padding-left: 10px;
  }

  .dx-list-item-before-bag {
    width: 25px;
  }
}

.hidden-select-all {
  .dx-list-select-all {
    display: none;
  }
}

.dx-dropdownbutton-content {
  max-height: 100vh;
}

.dx-datebox-wrapper-calendar {
  .dx-toolbar-after {
    .dx-toolbar-button {
      .dx-popup-cancel {
        .dx-button-text {
          color: $gray-2;
        }
      }
    }
  }
}

@each $size in $row-sizes {
  #root.root-#{$size} {
    $config: base_config($size);

    .popup-title, .popup__title__text-modifier {
      font-size: map.get($config, 'font-size') * $text-multiplier;
    }

    .popup__title__text-modifier {
      font-size: map.get($config, 'modifier-title-size');
    }

    .dx-placeholder::before, .dx-button-text, .dx-texteditor-input {
      font-size: map.get($config, 'font-size');
      text-overflow: ellipsis;
    }

    .popup__title-suffix-icons {
      @include icon($dark);

      i {
        cursor: pointer;
      }
    }

    .d5-link {
      font-size: map.get($config, 'font-size') !important;
    }
  }
}

