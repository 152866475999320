@import "variables";

.dx-toast-info.dx-toast-content {
  padding: 0;
  background-color: #FFFFFF;
  box-shadow: $notification-shadow;
}

.snackbar {
  &__title {
    height: 100%;
    font-style: normal;
    font-weight: 400;
    color: $dark;
    overflow-wrap: anywhere;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__message {
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: $gray-2;
  }
}

[data-type="help"] {
  .snackbar {
    &__title {
      margin-bottom: 20px;
    }

    &__message {
      margin-bottom: 20px;
    }

    &__link {
      font-style: normal;
      font-weight: 400;

      & a {
        text-decoration: none;
      }
    }
  }
}