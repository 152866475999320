@use "sass:math";
@import 'tables/variables';
@import 'configs/switch';


// Размеры свитча для таблицы и дерева
$table-switch-width: 23px;
$table-switch-height: 28px;
$table-switch-handle-size: 10px;

/*
// Размеры свитча как в дизайне
$table-switch-width: 20px;
$table-switch-height: 12px;
$table-switch-handle-size: 8px;
*/

// цвет круглого ползунка
$switch-on-handle-color: #fff;
// цвет включенного состояния
$switch-on-track-color: #00D058;
// цвет выключенного состояния
$switch-off-track-color: #6E7492;
// прозрачность неактивного свитча
$switch-off-opacity: .5;


@mixin switch-style($bg-color, $handle-color) {
  .dx-switch-container {
    &:before {
      background-color: $bg-color;
    }

    .dx-switch-inner {
      .dx-switch-handle {
        box-shadow: 0 0 0 1.5px $bg-color;

        &:before {
          background-color: $handle-color !important;
        }
      }
    }
  }
}

@mixin switch-size($height, $width, $handle-size, $margin) {
  width: $width !important;
  height: $height;

  .dx-switch-container {
    width: $width - 4px;
    height: math.div($height, 2);

    &:before {
      height: math.div($height, 2.1);
      margin: $margin;
    }

    .dx-switch-inner {
      display: flex;
      align-items: center;

      .dx-switch-handle {
        width: $handle-size + 2;
        height: $handle-size + 2;
        flex-basis: $handle-size + 2;
      }
    }
  }
}

.dx-switch {
  /* Общий стиль для неактивных(readonly) свитчей*/
  &.dx-state-readonly {
    opacity: $switch-off-opacity;
  }

  .dx-switch-wrapper {
    box-sizing: content-box !important;
    left: -8px;
  }

  @include switch-style($switch-off-track-color, $switch-on-handle-color);

  /* Общий стиль для включенного свитча */
  &.dx-switch-on-value {
    @include switch-style($switch-on-track-color, $switch-on-handle-color);
  }
}


@each $size in $row-sizes {
  #root.root-#{$size}, #root.root-#{$size} ~ .dx-overlay-wrapper, #root.root-#{$size} .dx-overlay-wrapper {
    $config: switch_config($size);

    $switch-control-width: map_get($config, 'control-width');
    $switch-control-height: map_get($config, 'control-height');
    $switch-control-handle-size: map_get($config, 'handle-size');

    .switch-control-wrapper {
      width: $switch-control-width;
      display: flex;
      overflow: hidden;
    }

    /* Стили свитча в фильтрах и форме редактирования BoolControl.jsx */
    .switch-control {
      @include switch-size($switch-control-height, $switch-control-width, $switch-control-handle-size, 0);
    }
  }
}

@each $size in $row-sizes {
  #root.root-#{$size} {
    $config: switch_config($size);
    $table-switch-margin: map_get($config, 'switch-margin');
    .table-wrapper .dx-treelist, .table-wrapper .dx-datagrid {
      .dx-switch {
        @include switch-size($table-switch-height, $table-switch-width, $table-switch-handle-size, $table-switch-margin);
        padding-top: 5px;
      }
    }
  }
}
