@use "sass:map";
@import "_variables";
@import "mixins";
@import "utils";

@mixin data-row-size($config) {
  .dx-treelist, .dx-datagrid {
    //ячейки с данными
    .dx-row.dx-data-row, .table-total-row {
      td {
        height: map.get($config, 'height');
        vertical-align: top !important;

        .dx-treelist-text-content {
          overflow: visible;
        }

        &:not(.dx-editor-cell) {
          font-weight: 400;
          @include table-data-cell-size($config);
        }

        .multiselect-column {
          $multiselect-margin: map.get($config, 'multiselect-margin');
          margin: $multiselect-margin;
        }

        .multiselect-container {
          $multiselect-padding: map.get($config, 'multiselect-padding');
          padding: $multiselect-padding;
        }

        &.dx-command-drag {
          $padding: map.get($config, 'padding');

          padding-top: $padding - 2;
          padding-bottom: $padding - 1px;
        }
      }
    }


    .dx-row.dx-data-row {
      td {
        &.d5-inline-edit-column, &.dx-command-drag {
          .dx-datagrid-drag-icon {
            font-size: map.get($config, 'font-size') + 2px;
          }
        }
      }
    }
  }
}

.table-wrapper {
  .vertical-align-top {
    .dx-data-row > td {
      vertical-align: top !important;
    }
  }

  // размеры ячеек height=30px
  .dx-treelist, .dx-datagrid {

    .dx-datagrid-revert-tooltip .dx-revert-button {
      display: none;
    }

    .dx-texteditor-buttons-container {
      .dx-button-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    //ячейки с данными
    .dx-data-row {
      td {
        &.d5-inline-edit-column {
          padding: 0 !important;
        }
      }

      //картинка в ячейке
      .row-img-block {
        text-align: center;

        img {
          width: 50px;
          height: auto;
        }
      }

      //ссылка в ячейке
      .row-link-block {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .column-switch-wrapper {
        height: 14px;

        .switch-svg {
          width: 22px;
        }

        &.state-readonly {
          .switch-svg-rect {
            opacity: 0.5;
          }

          .switch-svg[data-active=false] {
            .switch-svg-path {
              opacity: 0.8;
            }
          }
        }
      }
    }


    //ячейки с данными в невыделенной строке
    .dx-row.dx-data-row:not(.dx-selection) > td {
      color: $table-cell-color;
    }

    .fixed-columns-summary-wrapper {
      padding-right: 0;
    }
  }
}
