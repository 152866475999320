#app {
  .app-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.single-card {
  width: 100%;
  height: 100%;
  display: flex;

  &.dx-card {
    width: 330px;
    margin: auto;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
    }

    .content {
      padding: 40px;

      .login-header {
        text-align: center;
        margin-top: 10px;

        .login-header-title {
          display: flex;
          justify-content: center;
          align-items: center;

          .login-icon {
            font-size: inherit;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
