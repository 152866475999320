//primary - blue

$background: #E5E5E5; //RGBA(229,229,229,1)

$primary: #1453F8; // rgba(20, 83, 248, 1);
$primary-15: #D9E2FB;
$primary-30: #B3C5F6;
$primary-60: #668CED;
$primary-110: #003FE1;

$secondary: #34AFF9; //light blue rgba(52, 175, 249, 1)
$secondary-transparent: rgba(52, 175, 249, 0.1); //light blue rgba(52, 175, 249, 0.1)
$secondary-2: #D9F3FB;
$secondary-purple: #9665C5; // rgba(150, 101, 197, 1)

$saved-filter: #AE99DB;
$saved-filter-by-default: #8125D8;
$favorite-filter: #F2C94C;

$dark: #202A3C; //rgba(32, 42, 60, 1)
$dark-15: #ECEDEE;
$dark-30: #BCBFC5; //rgba(188, 191, 197, 1)
$black-tints-900: #1A1A1A;
$black: #000000;

$gray: #D4D7E4;
$gray-1: #36445E; //rgba(54, 68, 94, 1)
$gray-2: #6E7492; //rgba(110, 116, 146, 1)
$gray-readOnly: #ebebeb;
//бордеры в выделенных строках строках
$gray-5: #DEF3FF; //rgba(222, 243, 255, 1)
//Row alternation background color
$gray-6: #F3F5FA; //rgba(243, 245, 250, 1)

$gray-7: #C3CED7;

$error: #F7685B; //rgba(247, 104, 91, 1)
$success: #51C381; //rgba(81, 195, 129, 1)
$warn: #f0ad4e;

$white: #fff;

$yellow: #F2C94C;

$primary-dark: #797F8A;

$base-editor-height: 30px;

$notification-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);

//THEME--------------------------------------

$table-toolbar-height: 24px;
$table-toolbar-button-icon-size: 13px;
$context-menu-item-height: 32px;
$global-icon-size: 14px;






