@import "./variables";
@import "src/scss/ui3/tables/utils";
@import "src/scss/ui3/tables/variables";
@import "src/scss/ui3/mixins";
@import "configs/base";
@import "src/scss/prepend-slash";

@mixin boolean-form($size) {
  $config: base_config($size);
  .editor-settings-#{$size} {
    display: flex;
    align-items: center;

    .buttons-group {
      display: flex;

      & .dx-buttongroup-wrapper {
        display: flex;
        height: auto;

        .dx-buttongroup-item.dx-item.dx-button.dx-button-normal {
          // - 4 - это по 2пх бордеры
          height: map_get($config, 'field-height') - 4;
          box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
          flex: 1 1 0;

          .dx-button-content {
            padding: 0;
            height: map_get($config, 'field-height');
          }
        }
      }
    }

    .dx-button-text {
      font-size: map_get($config, "font-size");
    }
  }
}

@each $size in $row-sizes {
  @include boolean-form($size);
}

.icon-field {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d5-multi-select-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date-range {
  width: 100%;
  display: flex;

  .dx-texteditor-input-container {
    cursor: pointer;
  }

  input {
    pointer-events: none;
  }

  &__row {
    flex-direction: row;
  }

  &__column {
    flex-direction: column;
  }
}

.d5-field{
  min-width: 120px;
  @include field-border();

  &.switch-control,
  &.radio-group,
  &.image-control,
  &.check-box,
  &.decoration-item-text {
    border: none !important;
  }

  &.image-control {
    height: 100% !important;
    flex-shrink: 1;
    flex-grow: 0;

    & > img {
      display: block;
      height: auto;
      margin: auto;
    }
  }

  &.check-box {
    &.dx-state-readonly {
      .dx-checkbox-icon {
        background: $gray-readOnly;
      }
    }

    & .dx-checkbox-container {
      display: flex;
      align-items: center;
    }
  }


  //падингом управляется высота полей вроде бы всех кроме tagbox
  &.dx-editor-filled .dx-placeholder::before,
  &.dx-editor-filled .dx-texteditor-input,
  &.dx-editor-outlined .dx-placeholder::before,
  &.dx-editor-outlined .dx-texteditor-input {
    padding: 0 8px;
  }

  .dx-invalid-message > .dx-overlay-content {
    display: none;
  }

  &.dx-editor-outlined, &.dx-htmleditor-outlined {
    box-shadow: none !important;

    &.dx-state-disabled, &.dx-state-readonly, &.dx-state-hover {
      box-shadow: none !important;
    }

    &.dx-state-focused {
      border-color: $gray-2;
    }

    &.dx-invalid {
      @include field-border-invalid();
    }
  }

  &.dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-texteditor-input {
    color: $dark;
    overflow: hidden;
  }

  &.dx-state-readonly:not(.switch-control, .check-box, .blank-field) {
    background-color: $gray-readOnly;
    border-color: $gray-readOnly;

    &.dx-state-hover {
      border: 2px solid $gray-readOnly;
    }
  }

  &.dx-textarea {
    .dx-icon-clear {
      margin-top: 7px;
    }
  }

  &.dx-editor-outlined .dx-texteditor-buttons-container > .dx-button {
    margin-left: 0;
    margin-right: 3px !important;

    .dx-icon {
      color: $gray-2;
      fill: $gray-2;
    }
  }


  //замена всех дх дропдавн иконок на фонт овсом
  .dx-dropdowneditor-button, .dx-dropdowneditor, &.dx-dropdowneditor {
    .dx-dropdowneditor-icon {
      @include fa-icon-solid();
      font-size: 15px;
      color: $gray;

      &:before {
        content: prepend-slash("f078");
      }
    }

    &.dx-dropdowneditor-active {
      .dx-dropdowneditor-icon:before {
        color: $gray-2;
      }
    }
  }

  // для tagbox отдельно выставляем высоту
  &.multi-select-control,
  &.custom-tagbox {
    //отцентровка тагов
    .dx-tag {
      display: inline-block;
      max-width: 88px;

      & + .dx-texteditor-input {
        margin: 0 !important;
        padding: 0 !important;
      }

      .dx-tag-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #FFFFFF;
        background-color: $secondary;
        border-radius: 2px;
        font-weight: 400;
        padding-right: 27px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tag-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .dx-tag-remove-button {
        &:before {
          background-color: transparent;
          color: #FFFFFF;
        }
      }

      & + .dx-texteditor-input {
        width: auto !important;
      }
    }

    &.dx-editor-filled, &.dx-editor-outlined {
      //отцентровка ввода в tagbox (поиск)
      .dx-texteditor-input {
        padding: 0;
        height: 100%;
        margin-top: 0;
      }

      .dx-texteditor-buttons-container:last-child > .dx-button:last-child,
      .dx-texteditor-buttons-container:last-child > .dx-button:last-child {
        margin-right: 3px;
      }

      .dx-button {
        max-height: 28px;
        max-width: 34px;
      }

      //установка высоты в tagbox
      .dx-tag-container {
        margin: 0;
        overflow: hidden;
      }
    }
  }

  &.dx-state-readonly {
    &.blank-field {
      overflow: hidden;
      background-color: unset;
      height: 100%;

      .dx-texteditor-input-container .dx-texteditor-input {
        color: #000000;
      }

      .dx-texteditor-container {
        align-items: baseline;
      }
    }
  }

  &.buttons-group {
    .dx-buttongroup-item.dx-item.dx-button {
      &.dx-item-selected {
        color: $white;
        background-color: $secondary;

        .dx-button-content {
          .dx-button-text {
            color: $white;
          }
        }
      }

      .dx-button-content {
        .dx-button-text {
          text-transform: none;
        }
      }
    }
  }

  //

  &.dx-invalid.dx-show-invalid-badge {
    .dx-texteditor-input-container:after {
      display: none;
    }

    .dx-texteditor-buttons-container {
      position: relative;
    }

    .dx-texteditor-buttons-container:before {
      pointer-events: none;
      position: relative;
      left: -8px;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 17px;
      font-size: 13px;
      font-weight: 500;
      background-color: #f7685b;
      color: #fff;
      border-radius: 50%;
      content: "!";
      order: -2;
    }
  }

  &.dx-texteditor-empty, &.dx-invalid.dx-show-invalid-badge {
    & .dx-texteditor-container {
      & .dx-texteditor-buttons-container:before {
        // если обязательное поле ввода пустое, при сохранении формы
        // иконка восклицательного знака появляется с отступом от правого края.
        // для избежания отступа смещаем её правее
        margin-right: -21px;
      }

      & .dx-texteditor-buttons-container:first-child:before {
        display: none;
      }

      & .dx-texteditor-buttons-container {
        & .dx-clear-button-area {
          display: block;
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }
}

.dx-checkbox {
  min-width: 0;
}

.dx-checkbox-icon {
  width: 14px;
  height: 14px;
}

// Данное решение создавало баг №77796, закоментировал, баг №76531 не воспроизводится
/*.dx-texteditor-empty .dx-clear-button-area {
  // у дх в стилях display: none;, но из-за этого при отрисовке кнопки с крестиком в поле когда появляется значение,
  // поле меняет свой размер и выталкивает другие поля №0076531
  display: block;
  visibility: hidden;
  pointer-events: none;
}*/


.parent-field-container {
  //align-items: flex-end;
  width: 100%;
  display: flex;

  .parent-field-separator {
    width: 2px;
    background: $gray;
    height: 100%;
  }

  @include field-border();

  & > .form-field {
    flex: auto;
    margin: 0 !important;
    padding: 0 !important;

    .d5-field {
      border: none;
    }
  }
}
