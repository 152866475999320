@import "variables";

.page404-wrapper {
  color: #202A3C;
  margin-top: 48px;
  .oops {
    font-size: 60px;
    font-weight: 700;
  }
  .message {
    font-size: 32px;
    font-weight: normal;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .error-code {
    font-size: 14px;
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .link-list {
    margin-bottom: 20px;
    li {
      font-size: 14px;
      .link {
        text-decoration: none;
        color: $secondary;
      }
    }
  }
}
