@use "sass:map";
@import "variables";
@import "configs/context-menu";
@import "configs/icon-button";
@import "table-toolbar-mixin";

@mixin icon($color: null, $size: $global-icon-size) {
  $icon-size: $size;

  i, .dx-icon {
    @if $color {
      color: $color !important;
      fill: $color !important;
    }

    text-align: center;
    font-size: $icon-size;
    line-height: $icon-size + 1px;
  }

  .dx-icon, img, svg {
    height: $icon-size;
    width: $icon-size;
  }
}

@mixin icon_button($size, $color: null) {
  $config: get_icon_button_config($size);
  $widget-size: map_get($config, 'widget-size');
  $icon-size: map_get($config, 'icon-size');
  $icon-wrapper-padding: map_get($config, 'icon-wrapper-padding');

  height: $widget-size !important;
  min-width: $widget-size;

  .dx-button-content {
    padding: $icon-wrapper-padding;
  }

  @include icon($color, $icon-size);
}

@mixin button-settings() {
  @include toolbar-icon();
  min-width: 16px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin toolbar-hidden-menu-text($config) {
  font-size: map.get($config, 'font-size');
  line-height: map.get($config, 'line-height');
  font-weight: 400;
  color: $dark;
  text-transform: none;
}

@mixin context-menu($size) {
  $config: cm_config($size);
  .dx-context-menu {
    border-radius: 5px !important;

    .dx-submenu {
      border-radius: 5px !important;

      .dx-item-content.dx-menu-item-content {
        display: flex;
        align-items: center;

        & .dx-icon {
          height: 14px;
          line-height: 14px;
          width: 14px;
          margin-right: 8px;
        }
      }
    }

    .dx-menu-item-wrapper {
      border-bottom: 1px solid rgba(102, 102, 102, 0.08);

      .dx-menu-item {
        height: map.get($config, 'height');
        min-width: 230px;
      }

      &:last-child {
        border-bottom: none;
      }

      .menu-item-container {
        display: flex;
        flex: 1 0;
        align-items: center;
        position: relative;

        span {
          padding-left: 25px;
          @include toolbar-hidden-menu-text($config);
        }

        i {
          position: absolute;
          @include toolbar-icon();
        }
      }

      .dx-menu-item {
        span {
          @include toolbar-hidden-menu-text($config);
        }

        i {
          @include toolbar-icon();
        }
      }

      .dx-menu-item.dx-state-disabled {
        opacity: 1 !important;
        color: $gray;

        span {
          color: $gray;
        }
      }
    }
  }

  .help-context {
    .dx-menu-item-wrapper {
      &:last-child {
        .dx-menu-item-text {
          font-weight: 800;
        }
      }
    }
  }
}

@mixin field-border() {
  border: 2px solid $gray;
  border-radius: 5px !important;

  &:hover {
    border-color: $gray-2;
  }
}

@mixin field-border-invalid() {
  box-shadow: inset 0 0 0 1px rgb(247 104 91 / 40%) !important;
  border: 1px solid rgb(247 104 91 / 40%);
}


$group-font-size: 14px;
$group-font-color: #000;
$group-font-color-active: $primary;
$group-caption-padding: 8px;

@mixin group-caption-wrapper {
  padding-bottom: $group-caption-padding;
  margin-bottom: $group-caption-padding;
  position: relative;

  &:after {
    content: '';
    height: 3px;
    width: 100%;
    background: $gray;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@mixin group-caption {
  font-size: $group-font-size;
  text-transform: none;
  line-height: 20px;
  font-weight: 500;
  font-style: normal;
  color: $group-font-color;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin group-caption-active {
  @include group-caption;
  color: $group-font-color-active;
}

@mixin group-caption-tab {
  padding-right: 7px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  max-width: 100%;
  -webkit-user-drag: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin list-items-common-styles {
  .dx-item.dx-list-item {
    & .dx-list-item-content {
      & .dx-icon {
        color: #1A1A1A;
      }
    }

    & .dx-list-reorder-handle-container {
      & .dx-list-reorder-handle {
        color: #1A1A1A;
      }

      color: #1A1A1A;
    }

    &:hover:not(.dx-list-item-selected) {
      & .dx-list-item-content {
        & .dx-icon {
          color: $secondary;
        }
      }

      & .dx-list-reorder-handle-container {
        & .dx-list-reorder-handle {
          color: $secondary;
        }

        color: $secondary;
      }
    }

    &-selected {
      & .dx-list-item-content .dx-icon {
        color: rgba(255, 255, 255, 0.8);
      }

      & .dx-list-item-content,
      & .dx-list-reorder-handle-container {
        & .dx-list-reorder-handle {
          color: rgba(255, 255, 255, 0.8);
          background-color: $secondary;
        }

        color: rgba(255, 255, 255, 0.8);
        background-color: $secondary;
      }
    }
  }
}
