@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/visibility';
@import '~bootstrap/scss/utilities/overflow';
@import "scss/custom-bootstrap";
@import "scss/index";
@import "scss/ui3/index";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial, sans-serif;
}

#root {
  height: 100%;
  width: 100vw;
  display: flex;
  position: relative;
}

#section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
}

#header {
  z-index: 1;
}

#main {
  display: block;
  width: 100%;
  flex: 1 1 auto;
  min-height: 0;
}

.dx-header-row td .dx-datagrid-text-content {
  white-space: inherit !important;
}
