@use "sass:map";
@import "variables";
@import "mixins";
@import "tables/variables";
@import "table-toolbar-mixin";


.horizontal-separator {
  width: 2px;
  height: $table-toolbar-height;
  background: #D4D7E4;
}

.table-toolbar-wrapper {
  display: flex;
  flex: 0;
  align-items: center;

  .dx-button-content {
    display: flex;
    align-items: center;

    .dx-icon {
      padding: 0 !important;
      margin-right: 0 !important;
    }

    & div:first-child {
      display: inline-flex;
      align-items: center;

      & .dx-icon:not(img) {
        align-items: center;
        height: 100% !important;
        width: auto;
      }

      & .dx-button-text {
        padding-left: 4px;
      }
    }
  }

  .d5-table-toolbar {
    width: 100%;
    padding: 8px 0;

    .toolbar-item {
      display: flex;
      margin-right: 2px;

      &:last-of-type {
        margin-right: 0;
      }

      &.section-item {
        border: $dark-30 1px solid;
        border-left-width: 0;
      }

      &.section-item-first {
        border-left-width: 1px;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        margin-left: 2px;
      }

      &.section-item-last {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        margin-right: 2px;
      }
    }

    .horizontal-separator {
      margin-right: 4px;
    }

    .d5-is-pressed {
      i, span {
        color: $primary !important;
      }

      path {
        fill: $primary;
      }
    }

    .d5-react-svg-icon > div {
      width: 16px;
    }

    .dx-dropdownbutton {
      .d5-react-svg-icon > div svg {
        max-width: 16px;
        max-height: 16px;
      }
    }

    .toolbar-button, .toolbar-group-button, .dx-dropdownmenu-button {
      height: $table-toolbar-height;
      min-width: $table-toolbar-height;
      border-radius: 2px !important;

      & .dx-button-text {
        padding-left: 4px;
        max-width: 100px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .dx-dropdownmenu-button {
      .dx-icon {
        transform: rotate(90deg);
      }
    }

    .dx-item[aria-label='far fa-filter'] {
      .dx-button-content {
        padding-right: 0;
        padding-left: 5px;
      }

      + .dx-item {
        .dx-icon {
          margin-right: 2px;
        }
      }
    }

    .dx-button-has-text {
      .dx-button-content {
        padding: 4px;
      }
    }

    .toolbar-group-button {
      //если кнопка сплитнутая например кнопка отображения фильтра, то в ней рисуются
      //вложенные две кнопки вместо двух иконок
      .dx-dropdownbutton-toggle {
        .dx-button-content {
          flex: 0;
          padding: 0;
          width: 16px;
        }
      }

      .dx-button-content {
        display: flex;
        align-items: center;
        padding: 0 0 0 5px;

        .dx-icon:not(.dx-icon-spindown) {
          padding-right: 5px;
        }

        .dx-icon-spindown {
          height: 24px !important;
          width: 16px !important;

          &:before {
            font-size: 20px !important;
            height: 24px;
            position: relative;
            top: 3px;
          }
        }
      }
    }

    .dx-toolbar-items-container {
      height: $table-toolbar-height;
    }

    @include toolbar-button();
  }

  .row-size-button {
    .dx-button-content {
      .dx-icon {
        margin-right: 0;
        line-height: 18px;
      }

      .dx-button-text {
        display: none;
      }
    }
  }

  .row-size-button,
  .column-settings-button {

    &.dx-state-focused {
      background-color: rgba(0, 0, 0, 0);
    }

    @include toolbar-settings-button();
  }
}

.tab-settings-button {
  position: absolute;
  right: 14px;
  transform: translate(0, 20%);

  &.dx-state-focused {
    background-color: rgba(0, 0, 0, 0);
  }

  @include toolbar-settings-button();
}


.dx-dropdownmenu-popup-wrapper {
  & > div {
    border-radius: 5px !important;
  }
}

//меню в тулбаре там где три точки
.dropdown-list-wrapper, .group-button-dropdown {
  @each $size in $row-sizes {
    $config: cm_config($size);
    &.row-size-#{$size} {
      //каждый элемент списка должен быть подчеркнут
      & > .dx-list-item {
        border-bottom: 1px solid rgba(102, 102, 102, 0.08);

        &:last-child {
          border-bottom: none;
        }

        &.dx-state-disabled {
          opacity: 1 !important;
        }
      }

      @include toolbar-button();

      .hidden-menu-button, .toolbar-button {
        width: 100%;
        height: map.get($config, 'height') !important;

        .disabled {
          opacity: 1 !important;
        }

        .dx-button-content {
          padding: 0 8px;
          display: flex;
          align-items: center;
        }
      }

      //все иконки
      i, svg {
        @include toolbar-icon();
      }

      //весь текст
      span {
        @include toolbar-hidden-menu-text($config);
      }

      .dx-button-content {
        & div:first-child {
          display: flex;
          align-items: center;

          & .dx-button-text {
            padding-left: 4px;
          }
        }
      }

      .hidden-menu-button {
        .toolbar-group-button {
          padding: 0;

          & i {
            color: rgb(110, 116, 146);
          }
        }

        .dx-button-content {
          padding: 0 8px !important;
        }
      }
    }
  }
}
