@import "variables";

@mixin horizontal-resizer-icon {
  content: '';
  background-image: url("../../../public/icons/white-chevron-right.svg");
  background-repeat: no-repeat;
  width: 7px;
  height: 11px;
  position: absolute;
  top: calc(50% + 1px);
  left: -2px;
  cursor: pointer;
}

@mixin vertical-resizer-icon {
  content: '';
  background-image: url("../../../public/icons/white-chevron-down.svg");
  background-repeat: no-repeat;
  width: 11px;
  height: 9px;
  position: absolute;
  left: calc(50% + 2px);
  top: -5px;
  cursor: pointer;
}

.group-splitter-resizer {
  background: $secondary;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  position: relative;

  font-weight: 500;
  font-size: 11px;
  color: white;

  &.hidden {
    display: none;
  }
}

.group-splitter-resizer:hover {
  font-size: 15px;
}

.group-splitter-resizer.horizontal {
  height: 11px;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    height: 11px;
    width: 70px;
    background: $secondary;
    left: calc(50% - 28px);
    top: -5px;
    border-radius: 10px;
    cursor: pointer;
  }

  &:after {
    @include vertical-resizer-icon;
  }

  &.folded-vertically:after {
    @include vertical-resizer-icon;
    rotate: 180deg;
  }
}

.group-splitter-resizer.horizontal:hover {
  border-top: 5px solid transparent;
  border-bottom: 4px solid transparent;

  &:before {
    height: 14px;
    width: 74px;
    left: calc(50% - 32px);
    top: -6px;
  }

  &:after {
    width: 15px;
    height: 13px;
    left: calc(50% - 3px);
    top: -6px;
  }
}

.group-splitter-resizer.vertical {
  width: 11px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;

  &:before {
    content: '';
    position: absolute;
    width: 11px;
    height: 70px;
    background: $secondary;
    top: calc(50% - 28px);
    left: -5px;
    border-radius: 10px;
    cursor: pointer;
  }

  &:after {
    @include horizontal-resizer-icon;
  }

  &.folded-horizontally:after {
    @include horizontal-resizer-icon;
    rotate: 180deg;
  }
}

.group-splitter-resizer.vertical:hover {
  border-left: 5px solid transparent;
  border-right: 4px solid transparent;

  &:before {
    width: 14px;
    height: 74px;
    top: calc(50% - 32px);
    left: -6px;
  }

  &:after {
    width: 10px;
    height: 15px;
    top: calc(50% - 3px);
    left: -3px;
  }
}


.group-splitter-resizer.disabled {
  cursor: not-allowed;
}

.group-splitter-resizer.disabled:hover {
  border-color: transparent;
}
