@use 'sass:map';
@import 'tables/utils';
@import 'tables/variables';
@import 'configs/field';
@import "configs/base";

@mixin field-size($size, $config) {
  $font-size: map.get($config, 'font-size');
  $line-height: map.get($config, 'line-height');
  $input-padding: map.get($config, 'input-padding');
  $min-height: map.get($config, 'height');
  $icon-config: map.get($config, 'icons');
  $button-config: map.get($config, 'button');
  $exclamation-config: map.get($config, 'error-exclamation-sign');

  font-size: $font-size;
  line-height: $line-height;
  // min-height: $min-height;
  height: $min-height;

  input, textarea {
    padding: $input-padding !important;
    font-size: $font-size;
    line-height: inherit;
    height: auto !important;
  }

  @if $size == 'small' {
    textarea {
      padding-top: 4px !important;
    }
  }

  .dx-dropdowneditor-button {
    min-width: map.get($button-config, 'width');
    height: map.get($button-config, 'height');

    & .dx-button-content {
      & .dx-dropdowneditor-icon {
        &:before {
          color: #6E7492;
        }
      }

      &:hover {
        border-radius: 50%;
        background-color: #ebebeb;
      }
    }
  }


  .dx-button:not(.operation-type-widget) {
    &:not(.dx-buttongroup-item) {
      min-width: map.get($button-config, 'width');
      height: map.get($button-config, 'height');
    }

    box-shadow: none;


    .dx-button-content {
      padding: 0;
    }

    .dx-icon {
      width: map.get($icon-config, 'width');
      height: map.get($icon-config, 'height');
      font-size: map.get($icon-config, 'font-size');
      line-height: map.get($icon-config, 'height');
    }
  }

  &.multi-select-control, &.custom-tagbox {
    height: auto !important;
    min-height: $min-height;
  }

  .dx-placeholder {
    height: 100%;

    &:before {
      padding: map.get($config, 'placeholder-padding') !important;
      font-size: $font-size;
    }
  }

  .dx-texteditor-input-container::after {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: map.get($exclamation-config, 'width') !important;
    height: map.get($exclamation-config, 'height') !important;
    font-size: map.get($exclamation-config, 'font-size') !important;
  }

  .dx-tag-container {
    padding-top: 0;
    padding-bottom: 0;
    min-height: $min-height - 4; //- бордеры по 2пх

    .dx-tag {
      @include tag-box($size, $line-height, $font-size);
    }
  }
}

@mixin tag-box($size, $line-height, $font-size) {
  $medium-config: ('margin': 2px 5px 2px -2px, 'content-padding': 5px 32px 5px 8px);
  $large-config: ('margin': 6px 5px 6px -2px);
  $small-config: ('content-padding': 1px 32px 1px 8px);
  $config: $medium-config;

  @if $size == 'small' {
    $config: map.merge($medium-config, $small-config);
  }
  @if $size == 'large' {
    $config: map.merge($medium-config, $large-config);
  }

  margin: map.get($config, 'margin');

  //это если вложенные таг в таг
  .dx-tag {
    margin: 0;
  }

  .dx-tag-content {
    font-size: $font-size;
    line-height: $line-height;
    padding: map.get($config, 'content-padding');
  }
}

@mixin get-filter-size($size) {
  $config: base_config($size);
  .LinksTree__item-text {
    font-size: map.get($config, 'font-size');
  }
}

@mixin item-margin($size) {
  $config: base_config($size);
  padding: map_get($config, 'item-margin');
}


@mixin item-margin-class($size) {
  .item-margin {
    @include item-margin($size)
  }
}


@mixin get-field-size($size) {
  $config: field_config($size);
  $fontSize: map.get($config, 'font-size');
  .dx-button-text {
    font-size: $fontSize;
  }

  .field-label {
    font-size: $fontSize;
  }

  .decor-text {
    font-size: $fontSize;
  }

  .d5-field {
    @include field-size($size, $config);
  }
  .parent-field-container {
    min-width: 120px;
    @include field-size($size, $config);

    .d5-field {
      height: 100%;
      min-width: inherit;
    }
  }

  .clear-indicator {
    font-size: map.get($config, 'clear-indicator-size');
  }
}

@each $size in $row-sizes {
  .root-#{$size} {
    @include get-field-size($size);
    @include get-filter-size($size);
    @include item-margin-class($size);
  }
}

@mixin range-slider-control($size) {
  $config: base_config($size);
  $main-value: map_get($config, 'font-size');

  #root {
    &.root-#{$size} {
      .d5-range-slider {
        .dx-popup-content {
          font-size: $main-value;
          margin-top: -($main-value * 2);
        }

        .dx-slider-handle {
          &:after {
            width: $main-value;
            height: $main-value;
            margin-top: -($main-value / 2);
            margin-left: -($main-value / 2);
          }
        }

        .dx-slider-label {
          font-size: $main-value;
        }
      }
    }
  }
}

@each $size in $row-sizes {
  @include range-slider-control($size);
}
