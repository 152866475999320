$tableMaxHeight: 350px;
$kanbanMaxHeight: 950px;

.with-scroll, .d5-accordion {
  .kanban-form-wrapper {
    height: $kanbanMaxHeight;
  }
  .flex-form-edit-table .flex-d5-table {
    max-height: $tableMaxHeight;
  }
}

.has-split-panel {
  .flex-form-edit-table .flex-d5-table {
    max-height: 100%;
  }
}
