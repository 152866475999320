@import 'variables';
@import "mixins";

//стили таб панели
.tab-form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  //заголовки
  & > .dx-tabpanel-tabs {
    @include group-caption-wrapper;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-right: 30px;

    .dx-tabs-scrollable .dx-scrollable-content {
      width: 100%;
    }

    // панель под вкладками
    & > .dx-tabs {
      background: transparent;
    }

    .dx-tab {
      // высота 30
      padding: 8px 17px;

      .dx-tab-text, .tab-caption {
        @include group-caption;
        @include group-caption-tab;
      }

      //активный таб
      &.dx-tab-selected {
        background: transparent;
        //синяя полоска снизу
        &:before {
          z-index: 1;
          height: 3px;
        }

        .dx-tab-text, .tab-caption {
          @include group-caption-active;
          @include group-caption-tab;
        }
      }
    }

    // Заявка 78405
    .dx-tabs-wrapper {
      display: flex;
      flex: 1 0;
      overflow: hidden;
      text-align: left;

      .dx-tab {
        text-align: center;
      }
    }
  }

  .group-container {
    height: inherit;
  }
}
