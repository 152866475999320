svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -.125em
}

.svg-inline--fa.fa-2xs {
    vertical-align: .1em
}

.svg-inline--fa.fa-xs {
    vertical-align: 0
}

.svg-inline--fa.fa-sm {
    vertical-align: -.07143em
}

.svg-inline--fa.fa-lg {
    vertical-align: -.2em
}

.svg-inline--fa.fa-xl {
    vertical-align: -.25em
}

.svg-inline--fa.fa-2xl {
    vertical-align: -.3125em
}

.svg-inline--fa.fa-pull-left {
    margin-right: var(--fa-pull-margin, .3em);
    width: auto
}

.svg-inline--fa.fa-pull-right {
    margin-left: var(--fa-pull-margin, .3em);
    width: auto
}

.svg-inline--fa.fa-li {
    width: var(--fa-li-width, 2em);
    top: .25em
}

.svg-inline--fa.fa-fw {
    width: var(--fa-fw-width, 1.25em)
}

.fa-layers svg.svg-inline--fa {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
}

.fa-layers-counter, .fa-layers-text {
    display: inline-block;
    position: absolute;
    text-align: center
}

.fa-layers {
    display: inline-block;
    height: 1em;
    position: relative;
    text-align: center;
    vertical-align: -.125em;
    width: 1em
}

.fa-layers svg.svg-inline--fa {
    -webkit-transform-origin: center center;
    transform-origin: center center
}

.fa-layers-text {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    transform-origin: center center
}

.fa-layers-counter {
    background-color: var(--fa-counter-background-color, #ff253a);
    border-radius: var(--fa-counter-border-radius, 1em);
    box-sizing: border-box;
    color: var(--fa-inverse, #fff);
    line-height: var(--fa-counter-line-height, 1);
    max-width: var(--fa-counter-max-width, 5em);
    min-width: var(--fa-counter-min-width, 1.5em);
    overflow: hidden;
    padding: var(--fa-counter-padding, .25em .5em);
    right: var(--fa-right, 0);
    text-overflow: ellipsis;
    top: var(--fa-top, 0);
    -webkit-transform: scale(var(--fa-counter-scale, .25));
    transform: scale(var(--fa-counter-scale, .25));
    -webkit-transform-origin: top right;
    transform-origin: top right
}

.fa-layers-bottom-right {
    bottom: var(--fa-bottom, 0);
    right: var(--fa-right, 0);
    top: auto;
    -webkit-transform: scale(var(--fa-layers-scale, .25));
    transform: scale(var(--fa-layers-scale, .25));
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right
}

.fa-layers-bottom-left {
    bottom: var(--fa-bottom, 0);
    left: var(--fa-left, 0);
    right: auto;
    top: auto;
    -webkit-transform: scale(var(--fa-layers-scale, .25));
    transform: scale(var(--fa-layers-scale, .25));
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left
}

.fa-layers-top-right {
    top: var(--fa-top, 0);
    right: var(--fa-right, 0);
    -webkit-transform: scale(var(--fa-layers-scale, .25));
    transform: scale(var(--fa-layers-scale, .25));
    -webkit-transform-origin: top right;
    transform-origin: top right
}

.fa-layers-top-left {
    left: var(--fa-left, 0);
    right: auto;
    top: var(--fa-top, 0);
    -webkit-transform: scale(var(--fa-layers-scale, .25));
    transform: scale(var(--fa-layers-scale, .25));
    -webkit-transform-origin: top left;
    transform-origin: top left
}

.svg-inline--fa.fa-stack-1x {
    height: 1em;
    width: 1.25em
}

.svg-inline--fa.fa-stack-2x {
    height: 2em;
    width: 2.5em
}

.svg-inline--fa .fa-primary {
    fill: var(--fa-primary-color, currentColor);
    opacity: var(--fa-primary-opacity, 1)
}

.svg-inline--fa .fa-secondary {
    fill: var(--fa-secondary-color, currentColor)
}

.svg-inline--fa .fa-secondary, .svg-inline--fa.fa-swap-opacity .fa-primary {
    opacity: var(--fa-secondary-opacity, .4)
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
    opacity: var(--fa-primary-opacity, 1)
}

.svg-inline--fa mask .fa-primary, .svg-inline--fa mask .fa-secondary {
    fill: #000
}

.fa-duotone.fa-inverse, .fad.fa-inverse {
    color: var(--fa-inverse, #fff)
}
