@use "sass:math";
@use "sass:color";
@import 'src/scss/ui3/tables/variables';
@import 'src/scss/ui3/configs/icon-button';
@import "./variables";
@import "./mixins";

$operation-color: $primary;

@mixin widget-bg {
  background-color: color.change($secondary, $alpha: 0.2);

  &:hover {
    background-color: color.change($secondary, $alpha: 0.3);
  }
}

@mixin letter-a-icon {
  content: 'a..';
  position: relative;
  font-style: normal;
  font-weight: 500;
}

@mixin letter-W-icon {
  content: 'W';
  position: relative;
  top: 1px;
  font-style: normal;
  font-weight: 500;
}

@mixin condition-icons($icon-size) {
  i, .dx-icon {
    &.start-with::before {
      @include letter-a-icon();
    }

    &.by-words::before {
      @include letter-W-icon();
    }

    &.fa-arrows-left-right-to-line {
      font-size: $icon-size - 4px;
    }
  }
}

@mixin widget-icon($icon-size) {
  @include icon($operation-color, $icon-size);

  @include condition-icons($icon-size);
}

//кнопка для выбора операции
.operation-type-widget {
  border: none;
  margin-right: 8px;
  margin-left: 4px !important;
  border-radius: 2px !important;
  @include widget-bg();

  &[aria-expanded=true] {
    background-color: color.change($secondary, $alpha: 0.5);
  }

  .dx-icon-spindown {
    display: none;
  }

  .dx-buttongroup {
    font-size: 9px;
    display: flex;
  }

  &.dx-button {
    width: 100%;

    .dx-button-content {
      .fa-stack {
        display: flex;
        height: 100%;
        width: 100%;
        line-height: 1em;
      }
    }
  }

  .dx-button > .dx-button-content {
    top: -0.5px;
  }

  .dx-button-content > .fa-stack {
    display: flex;
    line-height: 1em;
    gap: 3px;
    width: 100%;
  }
}

//попап кнопки для выбора операции
.operation-type-widget-popup {
  width: 175px;
  padding: 10px !important;

  .dx-list-item-icon-container {
    border-radius: 2px;
    margin-right: 8px;
    @include widget-bg();
  }

  .dx-list-item, .dx-list-item-content {
    width: 100%;
  }
}

@each $size in $row-sizes {
  #root.root-#{$size} ~ .dx-overlay-wrapper, #root.root-#{$size} .dx-overlay-wrapper, #root.root-#{$size} {
    $config: get_icon_button_config($size);
    $widget-size: map_get($config, 'widget-size');
    $icon-size: map_get($config, 'icon-size');
    $icon-wrapper-padding: map_get($config, 'icon-wrapper-padding');

    .operation-type-widget {
      height: $widget-size !important;
      min-width: $widget-size;

      .dx-button-content {
        padding: $icon-wrapper-padding;
        @include condition-icons($icon-size);
      }

      @include icon_button($size, $operation-color);
    }

    .operation-type-widget-popup {
      .dx-list-item-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $widget-size;
        min-width: $widget-size;
        height: $widget-size;
        padding: $icon-wrapper-padding;
        @include widget-icon($icon-size);
      }
    }
  }
}
