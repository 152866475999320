@use 'sass:map';
@import 'tables/variables';
@import "configs/base";


@function dx_popup_config ($size) {
  $base_cfg: base_config($size);

  $small-config: (
    'popup-content-padding': 5px 10px,
  );

  $medium-config: (
    'popup-content-padding': 5px 10px,
  );

  $large-config: (
    'popup-content-padding': 10px,
  );

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }

  @return map.merge($base_cfg, $config);
}

@each $size in $row-sizes {
  #root.root-#{$size} ~ .dx-overlay-wrapper, #root.root-#{$size} .dx-overlay-wrapper{
    $config: dx_popup_config($size);

    .dx-overlay-content {
      overflow: visible;
      border-radius: 5px;

      .dx-popup-content:not(.d5-hint) {
        background: #fff;
        padding: map_get($config, 'popup-content-padding');

        .dx-item-content {
          font-size: map_get($config, 'font-size');
        }
      }
    }
  }
}
