@use "sass:map";
@import "utils";
@import "mixins";
@import "utils";
@import "src/scss/prepend-slash";


@mixin inline-borders {
  border: 1px solid $gray;
  border-radius: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

@mixin inline-editors-size($config) {
  .dx-treelist, .dx-datagrid {
    //ячейка во время инлайн редактирования
    td.dx-editor-cell {
      vertical-align: top !important;
      padding-left: 2px !important;
      padding-right: 2px !important;

      & .dx-dropdowneditor-icon {
        @include fa-icon-solid();
        font-size: map.get($config, 'icon-size');

        &:before {
          content: prepend-slash("f078");
          display: block;
          width: 18px;
          top: map.get($config, 'top');
          margin-top: -9px;
          left: 50%;
          margin-left: -9px;
          padding-top: map.get($config, 'dropdown-icon-padding-top');
        }
      }

      //убирает паддинги для чекбоксов в таблице
      &.dx-editor-inline-block:not(.dx-command-select)::before {
        padding: 0;
      }

      &.dx-editor-inline-block {
        & .dx-switch {
          padding-top: map.get($config, 'switch-padding-top') !important;
          margin-top: map.get($config, 'switch-margin-top') !important;
        }

        & .dx-switch-wrapper, .dx-switch-inner {
          display: flex;
          align-items: center;

          & .dx-switch-handle {
            width: map.get($config, 'switch-handle-icon-width') !important;
            height: map.get($config, 'switch-handle-icon-heigth') !important;
            flex-basis: map.get($config, 'switch-handle-icon-basis') !important;
          }
        }
      }

      .dx-texteditor {
        @include table-editing-cell-size($config);

        .dx-texteditor-input-container {
          align-items: center;
          padding: 0;
          margin: 3px 2px;

          &.dx-tag-container {
            @include inline-borders;
            padding: 0 7px;

            & .dx-texteditor-input {
              border: none;

              & + .dx-placeholder:before {
                padding: 0 1px !important;
              }
            }
          }
        }

        &.dx-state-disabled {
          background: rgba(255, 255, 255, 0);
          border-radius: 3px;
        }

        &.multi-select-control {
          $input-height: map.get($config, 'input-height');
          $multiselect-height: map.get($config, 'multiselect-height');

          min-height: $input-height;

          .dx-tag-container {
            min-height: $multiselect-height !important;
            margin: 0 2px;

          }

          .dx-texteditor-input {
            height: $multiselect-height !important;
          }
        }

        &-container {
          display: flex;
          align-items: center;
          border: 1px solid #D4D7E4;
          border-radius: 5px !important;
          min-height: map.get($config, 'height') !important;

          &:hover {
            border: 1px solid #668ced;
          }

          .dx-texteditor-input-container, .dx-texteditor-input {
            width: 100%;
            height: 100%;
            border: none !important;
            text-align: left !important;
          }

          .dx-texteditor-buttons-container {
            & .dx-clear-button-area {
              margin-right: 3px;
            }
          }
        }
      }

      .dx-placeholder {
        &:before {
          font-size: map.get($config, 'font-size');
          padding: map.get($config, 'placeholder-padding') !important;
          height: map.get($config, 'height');
          line-height: map.get($config, 'input-height');
        }
      }

      .dx-textarea {
        textarea {
          padding: 3px 7px;
          margin: 0;
          min-height: map.get($config, 'input-height');
          height: 100% !important;
          overflow: hidden;
          font-size: map.get($config, 'font-size');
          white-space: normal;
          text-align: justify;
          line-height: calc(1.32em + 1px) !important;
        }
      }

      .dx-checkbox {
        margin-top: map.get($config, 'checkbox-margin-top');

        &.dx-state-focused {
          display: none;
        }
      }

      & .dx-numberbox {
        & .dx-texteditor-input-container .dx-texteditor-input {
          text-align: right !important;
          padding-right: 2px;
        }
      }
    }

    .image-column {
      height: map.get($config, 'image-column-size');
      max-height: map.get($config, 'image-column-size');

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        $imgHeight: map.get($config, 'image-column-size');
        $controlPadding: map.get($config, 'padding');
        max-height: calc(100% - #{$imgHeight} - #{$controlPadding * 2} - 1px);
      }
    }
  }
}

.table-wrapper {
  .dx-datagrid-focus-overlay {
    &:after {
      background-color: unset;
    }
  }

  .dx-row.dx-edit-row > td {
    border-top: none !important;
    border-bottom: none !important;
  }

  .dx-texteditor-buttons-container {
    > div:empty {
      display: none;
    }
  }

  .dx-edit-row {
    input, textarea {
      @include inline-borders;
    }

  }

  .vertical-align-top {
    .d5-inline-edit-column {
      & > span {
        align-items: flex-start;
        padding-top: 6px;
      }
    }
  }


  .d5-inline-edit-column {
    border-left: none;

    & > span {
      display: inline-flex;
      justify-content: center;
      min-height: 100%;
    }

    .fa-pen-square {
      font-size: 1.2em !important;
      text-decoration: none;
      color: $secondary;

      &:before {
        color: transparent;
      }

      &:hover {
        &:before {
          font-size: inherit !important;
          color: $primary;
        }

        &:after {
          font-size: inherit !important;
          color: $secondary
        }
      }
    }
  }

  //кнопки в инлайн редактировании
  .d5-buttons-panel {
    padding-left: 0 !important;
    padding-right: 0 !important;
    vertical-align: middle;

    span {
      font-size: 1rem;
      margin-right: 6px;
      text-decoration: none;

      .save {
        cursor: pointer;
        color: $success;
      }

      .cancel {
        cursor: pointer;
        color: $gray-2;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.dx-tag + .dx-texteditor-input {
  border: none !important;
}

.dx-editor-cell.dx-focused .dx-texteditor-input {
  box-shadow: none !important;
  width: 100% !important;
}

.dx-tagbox-popup-wrapper {
  & .dx-list-select-all {
    display: none;
  }
}

@function get_paddingTop($size) {
  $small-config: ('padding-top': 2px);
  $medium-config: ('padding-top': 6px);
  $large-config: ('padding-top': 10px);

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }
  @return $config;
}

@function get_texteditorSizes($size) {
  $small-config: ('padding': 2px 7px);
  $medium-config: ('padding': 7px 7px);
  $large-config: ('padding': 9px 7px);

  $config: $medium-config;

  @if $size == 'small' {
    $config: $small-config;
  } @else if $size == 'large' {
    $config: $large-config;
  }
  @return $config;
}

@each $size in $row-sizes {
  #root.root-#{$size} ~ .table-wrapper, #root.root-#{$size} .table-wrapper {

    $config: get_paddingTop($size);

    .vertical-align-center {
      .d5-inline-edit-column {
        & > span {
          padding-top: map_get($config, 'padding-top');
        }
      }
    }

    $config: get_texteditorSizes($size);

    textarea.dx-texteditor-input {
      padding: map_get($config, 'padding');
    }
  }
}

.dx-dropdowneditor-overlay {
  & .dx-overlay-content {
    min-width: fit-content !important;
  }
}
