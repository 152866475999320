@import "variables";
@import "mixins";

.hiddenGroup {
  display: none;
}

.group-container {
  position: relative;
  overflow: hidden;
  flex: 1 1 auto;

  .group-caption-wrapper {
    display: flex;
    flex: 0 1 auto;
    white-space: nowrap;
    @include group-caption-wrapper;

    &.hidden {
      display: none;
    }

    .group-caption {
      padding-left: 6px;
      @include group-caption;
    }

    .button-question {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);

      & i {
        color: rgba(0, 0, 0, 0.54);
        font-size: 12px;

        &:hover {
          color: #1453f8;
        }
      }
    }
  }

  .group-content {
    flex: 1 1 auto;
  }
}

.grid-group-content {
  display: grid;
  overflow: hidden;
}


//базовые стили
.group-item {
  //стили для элементов
  &.item {
    &.--fields.--hidden {
      //flex: 0 0 auto;
      display: none;
    }

    &.--default {
      //Поставил flex-basis 0 для канбана, потому что он улетает в оверфлоу
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
    }

    &.--subforms {
      flex: 1 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      &.--table {
        min-height: 100px;
      }
    }

    &.--orientation-row {
      &.--fields {
        flex: 1 1 auto;
      }

      &.--decors {
        flex: 0 0 auto;
      }
    }

    &.--orientation-col {
      &.--fields {
        flex: 0 1 auto;
      }

      &.--decors, &.--resizable-items {
        flex: 1 1 auto;
      }

      &.--resizable-items {
        .d-layout-item-container {
          display: flex;
          flex-direction: column;
          overflow: hidden;

          .layout-field__wrapper {
            flex: 1 1 0 !important;
            overflow: hidden;
          }
        }
      }
    }
  }

  .group-with-caption {
    & > .--table {
      margin-top: -$group-caption-padding;
    }
  }

  //стили для групп
  &.group {
    &.--hidden, &.--empty {
      display: none;
    }

    &.--accordion {
      max-height: 100%;
      flex: 0 0 auto;
    }

    &.--only-small-items {
      flex: 0 1 auto;
    }

    &.--has-accordion {
      flex: 1 1;
      flex-basis: 0;
      min-width: 0;
      min-height: 0;
    }

    &.--edit-subform {
      max-height: 100%;
      overflow: hidden;

      &:not(.group-item--has-width) {
        flex: 1 1;
        flex-basis: 0;
      }
    }
  }
}


//группы в сплитере
.Pane {
  & > .group {
    height: 100%;
  }
}

//частные костыли
.flex-row {
  //группы расположенные строкой
  & > .group-item {
    &.--accordion:not(.group-item--has-width) {
      flex: 1 1;
      flex-basis: 0;
      min-width: 1px;
    }
  }
}

//Стили для фулскрина
.flex-full-screen-wrapper {
  overflow: hidden;
  height: 100%;
  max-height: 100%;

  .SplitPane {
    position: relative !important;

    &.horizontal {
      .flex-container {
        .flex-d5-table {
          min-height: unset;
        }
      }
    }
  }

  .d5-accordion {
    .flex-column {
      & > .group-item {
        flex: 1 1 auto !important;
      }
    }
  }

  .tab-form {
    max-height: 100%;
    width: 100%;

    .flex-column {
      & > .group-item {
        flex: 1 1;
        flex-basis: auto;

        &.--only-small-items {
          flex: 0 1;
          margin-top: 5px;
        }
      }
    }
  }
}

//стили для сколл контейнера
.d5-scrollview {
  height: 100%;
  width: 100%;

  .group-container {
    min-width: 0;
    max-height: inherit;
    overflow: visible;
  }

  .group-content {
    overflow: visible;
  }


  .flex-column {
    & > .group-item {
      overflow: visible !important;

      &.item.--subforms, &.group.--orientation-col, &.group.--has-accordion {
        flex: 1 1 auto !important;
      }
    }
  }
}

.help-cursor {
  cursor: help;
}


