@import "./variables";
@import 'scss/ui3/tables/utils';
@import 'scss/ui3/tables/variables';
@import 'scss/ui3/configs/header-filters';

@mixin clear-button {
  .dx-texteditor-container {
    .dx-texteditor-buttons-container {
      .dx-clear-button-area {
        width: 20px;
        margin: 0;

        .dx-icon-clear {
          width: 10px;
          height: 10px;
          line-height: 10px;
          top: 50%;
          left: 50%;
          margin-left: -5px;
          background-color: $gray;

          &:before {
            font-size: 5px;
            color: $dark;
          }
        }
      }
    }
  }
}

.header-filter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .filter-content {
    margin: -1px;
    height: 100%;
    margin-bottom: 8px;
    display: flex;
    overflow: hidden;

    .d-layout-item-container {
      height: inherit;
      padding: 0;
      //----- если у фильтра проставлено LocationOnGrid, то для фильтра лейки не нужно применять это свойство
      max-width: 100% !important;
      width: 100% !important;
      //-----

      .dx-list-search {
        border-radius: 5px;
        height: 30px;
        border: 2px solid $gray;
        box-shadow: none;
        padding-top: 0;

        &:hover {
          border: 2px solid $gray-2;
        }

        .dx-icon-search {
          display: none;
        }

        &.dx-show-clear-button {
          .dx-texteditor-container {
            .dx-texteditor-buttons-container {
              .dx-clear-button-area {
                width: 20px;
                margin: 0;

                .dx-icon-clear {
                  width: 10px;
                  height: 10px;
                  line-height: 10px;
                  top: 50%;
                  margin-top: -5px;
                  left: 50%;
                  margin-left: -5px;
                  background-color: $gray;

                  &:before {
                    font-size: 5px;
                    color: $dark;
                  }
                }
              }
            }
          }
        }
      }

      .dx-scrollable-wrapper {
        padding-bottom: 10px;
        border-bottom: 1px solid $gray-6;
      }

      &.item-margin {
        margin: 0 !important;
      }

      .d-layout-item {
        height: inherit;

        .header-filter-field-container {
          height: inherit;
          align-items: baseline;

          &.dictionary-container {
            margin: 0 5px 0 10px;
            display: flex;
            flex-direction: column;

            & > .dx-list {
              overflow-x: visible;
              overflow: unset;
            }
          }

          .d5-field {
            @include clear-button;
          }

          &.without-widget {
            .operation-type-widget {
              display: none;
            }
          }

          .dx-buttongroup.buttons-group {
            &.d5-field:hover {
              border: 2px solid $gray;
            }

            .dx-buttongroup-item {
              color: $dark;
              flex: 1;

              &.dx-button {
                box-shadow: none;
              }

              &.dx-item-selected {
                background-color: $secondary;
                color: $white;

                &:before {
                  display: none;
                }

                &.dx-buttongroup-first-item {
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  margin-left: -2px;
                }

                &.dx-buttongroup-last-item {
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                  margin-right: -2px;
                }

                .dx-button-content {
                  .dx-button-text {
                    color: $white;
                  }
                }
              }

              .dx-button-content {
                .dx-button-text {
                  text-transform: none;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .header-filter-radiogroup {
      margin: 5px 20px;
      height: 75px;
    }
  }

  .header-filter-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 80px;

    .dx-button {
      border-radius: 5px;

      &:last-child {
        margin-left: 10px;
      }

      &.apply-button {
        background-color: $primary;
        color: $white;

        &:hover {
          background-color: $primary-110;
        }
      }

      &.cancel-button {
        box-shadow: none;
        color: $gray-2;

        &:hover {
          background-color: $gray-6;
        }
      }

      .dx-button-content {
        .dx-button-text {
          text-transform: none;
        }
      }
    }
  }
}

.dx-overlay-content .dx-popup-normal .dx-resizable .dx-popup-flex-height {
  top: -10px !important;
}

@mixin header-filter-content($size) {
  $config: header_filter_settings_config($size);
  .header-filter-mode-#{$size} {
    padding: map_get($config, 'popup-wrapper');

    .form-filter {
      display: block;
    }

    .d5-field {
      min-height: map_get($config, "input-height");

      input.dx-texteditor-input {
        font-size: map_get($config, 'font-size');
      }
    }

    .dx-tag {
      margin-top: map_get($config, 'dx-tag');
    }

    .dx-placeholder {
      display: flex;
    }

    .dx-placeholder:before {
      display: flex;
      align-items: center;
      font-size: map_get($config, 'font-size') !important;
      padding: map_get($config, "input-padding") !important;
    }

    .dx-texteditor-container {
      .dx-icon {
        &.fa-calendar,
        &.fa-clock {
          font-size: map_get($config, "icon-size");
        }
      }
    }

    .dx-texteditor-input {
      padding: map_get($config, "input-padding") !important;
    }

    .dx-buttongroup {
      height: map_get($config, 'input-height') !important;

      .dx-buttongroup-item {
        height: auto;
      }

      .dx-button-text {
        font-size: map_get($config, 'font-size');
      }

      .dx-button-content {
        padding: 0;
      }
    }

    .dx-list-item-content {
      display: flex;
      align-items: center;
    }

    .d5-multi-select-item, .dx-button-text {
      font-size: map_get($config, 'font-size');
    }
  }
}

@each $size in $row-sizes {
  @include header-filter-content($size);
}
